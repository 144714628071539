<template>
  <div class="animated fadeIn">
    <b-form @reset="onReset" @submit.prevent="onSubmit">
      <h4 class="text-success mb-3"><i class="fa fa-envelope"></i> New Message <span v-html="recipient.label"></span></h4>
      
      <b-alert variant="info" :show="true" class="text-center">
        <i class="fa fa-info-circle"></i> The following interface is currently in Beta phase. If you experience any issues or hear reports of any issues, 
        please let us know via <a href="mailto:webmaster@nzonesports.com">webmaster@nzonesports.com</a>
      </b-alert>
      
      <!-- b-form-select
        class="mb-4"
        v-model="recipients"
        :options="recipientOptions"
      >
        <template v-slot:first>
          <option :value="null" disabled>-- Select Recipient Group --</option>
        </template>
      </b-form-select -->
      
      <div v-if="Object.keys(recipients).length" class="my-3">To: 
        <template v-for="(group, eid, _) in recipientList">
          <div v-if="group.recipients.length" class="border border-info p-1 mb-3" :key="group.id">
            <div class="text-success"><b v-if="Object.keys(recipientList).length > 1"><a
              href="#"
              class="text-warning border border-warning px-1 mr-1 ml-1"
              :title="'Remove ' + group.event.title"
              @click.prevent="onRemoveEvent(eid)"
            >X</a> {{ group.event.title }}</b></div>
            <small
              v-for="(thisRecipient, i) in group.recipients"
              class="d-inline-block border border-success p-1 m-1"
              :key="i"
            ><b><a
              href="#"
              class="text-warning border border-warning px-1 mr-1"
              :title="'Remove ' + thisRecipient.fullName"
              @click.prevent="onRemoveRecipient(thisRecipient['@id'])"
            >X</a></b> <a
              href="#"
              class="text-dark"
              :title="'View ' + thisRecipient.fullName"
              @click.prevent="$refs.userViewModal.open(thisRecipient.id)"
            >{{ thisRecipient.fullName }}</a></small>
          </div>
        </template>
      </div>

      <b-form-group
        id="fieldset-message-subject"
        label-cols-sm="2"
        label-cols-lg="1"
        label="Subject:"
        label-for="message-subject"
      >
        <b-form-input id="message-subject" v-model="subject"></b-form-input>
      </b-form-group>
      
      <!-- EDITOR -->
      <!-- RichTextEditor
        class="mb-4"
        :content="body"
        @input="setMessage"
      / -->
      <b-form-textarea rows="12" class="mb-4" v-model="body"></b-form-textarea>
      <b-form-group
        id="fieldset-message-reply-to"
        label-cols-sm="2"
        label-cols-lg="1"
        label="Reply Email:"
        description="Replies will be sent to this email address by default"
        label-for="message-reply-to"
      >
        <b-form-input id="message-reply-to" v-model="replyTo"></b-form-input>
      </b-form-group>

      <b-row>
        <b-col>
          <!-- b-btn type="reset" variant="danger">Reset</b-btn -->
        </b-col>
        <b-col class="text-right">
          <b-btn type="submit" variant="success" :disabled="recipients.length < 1"><i class="fa fa-send"></i> Send</b-btn>
        </b-col>
      </b-row>
    </b-form>
    <UserViewModal ref="userViewModal" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// import RichTextEditor from '@/components/RichTextEditor'
import { constants } from '@/shared/constants'
import UserViewModal from '../../../components/UserViewModal.vue'

const EVENT_TARGET = {
  ALL: 'All',
  UNPAID: 'Unpaid & Pending'
}

export default {
  name: 'compose',
  components: {
    // RichTextEditor
    UserViewModal
  },
  data () {
    return {
      recipient: {
        type: null,
        id: null,
        label: null
      },
      // recipientOptions: [],
      recipientList: {},
      recipients: [],
      subject: '[N Zone Sports] Please Read',
      body: null,
      replyTo: null
    }
  },
  computed: {
    ...mapGetters([
      'eventRegistrations',
      'eventGroups',
      'user'
    ]),
    fullName () {
      return this.currentUser.fullName
    }
  },
  methods: {
    ...mapActions([
      'getEventRegistrations',
      'getUser',
      'getUsers',
      'getEventGroups',
      'postMessage'
    ]),
    setMessage (val) {
      this.body = val
    },
    onRemoveRecipient (recipientIRI) {
      const i = this.recipients.indexOf(recipientIRI)
      if (this.recipients.length) {
        this.recipients.splice(i,1)
      }
      for (let eid in this.recipientList) {
        const j = this.recipientList[eid].recipients.findIndex(u => u['@id'] === recipientIRI)
        if (j > -1) {
          this.recipientList[eid].recipients.splice(j,1)
        }
      }
    },
    onRemoveEvent (eid) {
      for (let i in this.recipientList[eid].recipients) {
        const j = this.recipients.indexOf(this.recipientList[eid].recipients[i]['@id'])
        if (j > -1) {
          this.recipients.splice(j,1)
        }
      }
      delete this.recipientList[eid]
    },
    onReset () {},
    async onSubmit () {
      await this.postMessage({
        business: this.currentBusiness['@id'],
        sender: this.currentUser['@id'],
        recipients: this.recipients,
        subject: this.subject,
        body: this.body,
        replyTo: this.replyTo
      })

      this.showSuccess({ message: 'Your message is being sent!' })
      this.$router.go(-1)
    },

    /**
     * initialize message to event groups
     */
    async iniGroupMessage () {
      const eventGroups = await this.getEventGroups({
        id: this.$route.params.pgid
      })
      let group = this.eventGroups[0]
      this.recipient.type = constants.MESSAGE_TYPE_EVENT_GROUP
      this.recipient.id = group.id
      this.recipient.label = `to ${group.event.title}: Group ${group.name}`
      this.recipients.push(group['@id'])
      this.subject = `[N Zone Sports] ${group.event.title}`
      return eventGroups
    },

    /**
     * initialize message to users
     */
    async iniUserMessage () {
      const user = await this.getUser(this.$route.params.uid)
      this.recipient.type = constants.MESSAGE_TYPE_MEMBER
      this.recipient.id = this.$route.params.uid
      this.recipient.label = `to <span style="whitespace:nowrap">${this.user.fullName}<span>`
      this.recipients.push(constants.IRI_USERS + this.recipient.id)
      return user
    },

    /**
     * initialize message to event registants
     */
    async iniRegistrationsMessage () {

      // initialize query params
      const params = {
        event: this.$route.params.eid,
        pagination: false
      }
      let target

      // set message data based on message type
      switch (this.$route.params.message) {
        case 'unpaid':
          target = EVENT_TARGET.UNPAID + ((Array.isArray(this.$route.params.eid) && this.$route.params.eid.length > 1) ? ' Registrants (multiple events)' : ' Registrants')
          // params['invoice.paymentMethod.paymentOption'] = 1
          // params['exists[invoice.datePaid]'] = false
          params['exists[invoice.transactionId]'] = false
          // this.subject = `[N Zone Sports] ${this.eventRegistrations[0].event.title} (UNPAID)`
          this.subject = `[N Zone Sports] Outstanding Invoice Notice`
          this.body = `Hello,

We noticed that you have an unpaid invoice for an N Zone Sports event registration.` +
` Please note that an event registration is not complete until payment is received in full.

You can find your invoice(s) by logging in at https://nzonesports.com/${this.currentBusiness.uriName}/ and clicking Member > Registrations.` +
` Then click Pay Invoice, which can be found beneath the event you registered for.

If you have any questions at all, please do not hesitate to contact us - we'll be happy to assist you.

Looking forward to seeing you!`
          break
        default:
          target = EVENT_TARGET.ALL
      }

      // get registrations
      const registrations = await this.getEventRegistrations(params)
      if (!registrations.length) {
        this.showMessage({ message: 'No registrations found!' })
        this.$router.go(-1)
        return
      }
      // set recipient data
      this.recipient.label = `to ${target}`
      for (let i in this.eventRegistrations) {
        const recipientIRI = this.eventRegistrations[i].user['@id']
        if (
          !this.recipients.includes(recipientIRI)
        ) {
          this.recipients.push(recipientIRI)

          const eid = this.eventRegistrations[i].event.id
          if (!this.recipientList[eid]) {
            this.recipientList[eid] = {}
            this.recipientList[eid].event = this.eventRegistrations[i].event
            this.recipientList[eid].recipients = []
          }
          this.recipientList[eid].recipients.push(this.eventRegistrations[i].user)
        }
      }

      return registrations
    },
    async iniDirectorsMessage () {
      // set users data
      const users = await this.getUsers({
        pagination: false,
        roles: "ROLE_DIRECTOR",
        active: true
      }).then(resp => {
        resp.forEach(user => {
          this.recipients.push(constants.IRI_USERS + user.id)
        })
      })
      this.recipient.type = constants.MESSAGE_TYPE_DIRECTOR
      // this.recipient.id = this.$route.params.uid
      this.recipient.label = `to <span style="whitespace:nowrap">Active Directors<span>`
      return users
    }
  },
  async mounted () {
    if (this.$route.params.pgid) {
      await this.iniGroupMessage()
    }
    else if (this.$route.params.uid) {
      await this.iniUserMessage()
    }
    else if (this.$route.params.eid && this.$route.params.message) {
      await this.iniRegistrationsMessage()
    }
    else if (this.isAdmin && this.$route.query.activeDirectors) {
      await this.iniDirectorsMessage()
    }

    this.replyTo = this.currentUser.email
    // this.body = `<p></p><p>--<br />${this.currentUser.fullName}<br />
    // N Zone Sports of ${this.currentBusiness.areaName}<br />
    // ${this.currentBusiness.phone}</p>`
    let businessInfo = ''
    if (this.currentBusiness.areaName) {
      businessInfo = ` of ${this.currentBusiness.areaName}\n${this.currentBusiness.phone}`
    } else {
      businessInfo = ` of America\nwww.nzonesports.com`
    }
    this.body = this.body + `\n\n${this.currentUser.fullName}\nN Zone Sports${businessInfo}\n`
  }
}
</script>
